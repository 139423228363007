<template>
  <section class="mb-16">
    <h3 class="text-h6 secondary--text mb-0">
      Solicitudes de obras, bienes o servicios
    </h3>
    <p>Ejercicio {{ anioFiscal }}</p>
    <p class="text-center mt-16">¿Qué tipo de compra desea realizar?</p>

    <v-radio-group v-model="tipo_compra">
      <section>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="3" md="2">
            <v-card class="me-5 ms-5" height="200">
              <v-card-title>
                <v-radio value="1" />
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-icon color="primary" size="50"> mdi-file-tree </v-icon>
              </v-card-text>
              <v-card-text class="d-flex justify-center">
                <p class="text-center">Proceso de compra</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" v-if="false">
            <v-card class="me-5 ms-5" height="200">
              <v-card-title>
                <v-radio value="2" />
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-icon color="primary" size="50">
                  mdi-card-account-details-star
                </v-icon>
              </v-card-text>
              <v-card-text class="d-flex justify-center">
                <p class="text-center">Catálogo convenio Marco</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" v-if="false">
            <v-card class="me-5 ms-5" height="200">
              <v-card-title>
                <v-radio value="3" />
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-icon color="primary" size="50"> mdi-web </v-icon>
              </v-card-text>
              <v-card-text class="d-flex justify-center">
                <p class="text-center">Compra en línea</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-radio-group>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="12" class="d-flex justify-center">
        <v-btn color="primary" dark @click="continuar()" :loading="isLoading"> Continuar </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "solicitudCreateView",
  components: {},
  data: () => ({
    isLoading: false,
    tipo_compra: 0
  }),
  computed: {
    ...mapState(["selectedUnidad", "anioFiscal"])
  },
  methods: {
    ...mapMutations("agregarInsumo", ["setIdSolicitud"]),
    continuar() {
      this.isLoading = true;
      if (this.tipo_compra !== 0) {
        this.addSolicitudCompra();
      } else {
        this.temporalAlert({
          show: true,
          message: "Debe seleccionar un tipo de compra",
          type: "error"
        });
        this.isLoading = false;
      }
    },
    async addSolicitudCompra() {
      let body = {
        anio: this.anioFiscal,
        id_unidad: this.selectedUnidad?.id,
        id_solicitud_tipo: Number(this.tipo_compra)
      };

      const { data, status } =
        await this.services.SolicitudCompra.postSolicitudCompra(body);
      if (status === 201) {
        if(Number(this.tipo_compra) === 1){
          this.$router.push({
            name: "list-obs-proceso-tradicional",
            params: {
              id_solicitud: data.id
            }
          });
        } else {
          this.$router.push({
            name: "compras",
            params: {
              tipo: Number(this.tipo_compra),
              id_solicitud: data.id
            }
          });
        }
        this.setIdSolicitud(data.id);
      }
    }
  },
  created() {
    this.$store.state.comprasModule.pasoSolicitud = null;
  }
};
</script>
